export type RegisterModel = {
  email: string;
  gender?: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  password: string;
  confirmPassword: string;
  emailOffers: boolean;
  smsOffers: boolean;
  phoneNumber: string;
};

export type InquiryModel = {
  StringValue: string;
  BoolValue: boolean;
  DateTimeValue: string;
};

export const DefaultRegisterModel: RegisterModel = {
  email: '',
  firstName: '',
  lastName: '',
  dateOfBirth: '',
  password: '',
  confirmPassword: '',
  emailOffers: false,
  smsOffers: false,
  phoneNumber: ''
};

export const DefaultInquiryModel: InquiryModel = {
  StringValue: '',
  BoolValue: false,
  DateTimeValue: ''
};

