import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from 'services/appContext/AppContext';
import useContentfulEntries from 'services/contentful/useContentfulHooks';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import useUserService from 'services/eva/UserService';
import { DefaultMemberCardModel } from './MemberCardModel';

export default function MemberCard(): JSX.Element {
  const { appContext, setAppContext } = useContext(AppContext);
  const [url, setMemberContent] = useState<any>();
  const intl = useIntl();
  const history = useHistory();
  const [model, setModel] = useState(DefaultMemberCardModel);
  const userService = useUserService();
  const [assets] = useContentfulEntries();

  useEffect(() => {
    const assetTitle = 'SS_success';
    if (assets && assets?.items && assets.items.length) {
      assets.items.map((asset) => {
        if (asset.fields.title === assetTitle) {
          setMemberContent(asset.fields.file.url);
        }
        return asset;
      });
    } else {
     setMemberContent(null);
    }
  }, [assets])

  useEffect(() => {
    (async () => {
      try {
        const userInfo = await userService.getUserInfo();

        if (userInfo && model.userId !== userInfo.User.ID) {
          setModel(prev => {
            return {
              ...prev,
              firstName: userInfo.User.FirstName,
              lastName: userInfo.User.LastName,
              userId: userInfo.User.ID
            };
          });

      }
      } catch (err) {
        setAppContext({
          ...appContext,
          userToken: undefined
        });
        history.push('/');
      }
    })();
  }, [history, userService, setAppContext, model, appContext]);

  return (
    <div className="success-card-container">
      { url &&
          <div className="congrats-img" style={{backgroundImage:"url(" + url + ")"}}>
            <div className="congrats-img-text">
              <div><FormattedMessage id="congrats.img.text.first" /></div>
              <div><FormattedMessage id="congrats.img.text.second" /></div>
            </div>
            <div className="congrats-button">
              <a href={intl.formatMessage({ id: 'btn.discover.' + appContext.locale  })} >
                <button className="discover-btn"><FormattedMessage id={"btn.discover"} /> </button>
              </a>
            </div>
          </div>
      }
      <div className="congrats-text">
        <div className="congrats-title"><FormattedMessage id="congrats.title" /></div>
        <div className="congrats-description"><FormattedMessage id="congrats.description" /></div>
      </div>
    </div>
  );
}
