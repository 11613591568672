import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from 'services/appContext/AppContext';
import { useErrors } from 'services/errorHandling/useErrors';
import flags from 'assets/images/flag/';
import { get } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import facebookLogo from 'assets/images/icon_system_social_facebook.svg';
import instagramLogo from 'assets/images/icon_system_social_instagram.svg';
import pinterestLogo from 'assets/images/icon_system_social_pinterest.svg';
import twitterLogo from 'assets/images/icon_system_social_twitter.svg';
import youtubeLogo from 'assets/images/icon_system_social_youtube.svg';
import linkedinLogo from 'assets/images/icon_system_social_linkedin.svg';
import tiktokLogo from 'assets/images/icon_system_social_tiktok.svg';
import { privacyLinks, termsLinks, cookieLinks } from 'translations';
import Select from './form/Select';

export default function Footer(): JSX.Element {
  const { getErrorHandler } = useErrors();
  const { appContext, setAppContext } = useContext(AppContext);
  const [selectedLanguage, setSelectedLanguage] = useState<string>();
  const [iconFlag, setIconFlag] = useState<string>();

  const intl = useIntl();

  const handleLanguageChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setAppContext({
      ...appContext,
      language: event.target.value,
      locale: `${event.target.value}-${appContext.env.toUpperCase()}`
    });
  };

  useEffect(() => {
    if (appContext.language !== selectedLanguage) {
      setSelectedLanguage(appContext.language);
    }
  }, [appContext, selectedLanguage]);

  useEffect(() => {
    if (selectedLanguage) {
      const langId = selectedLanguage;
      setIconFlag(get(flags, langId));
    }
  }, [selectedLanguage, setIconFlag]);

  return (
    <footer>
      <div className="info">
        <div className="language">
          <Select
            titleId="footer.language.select"
            errorId=""
            value={selectedLanguage}
            onChange={handleLanguageChange}
            errorHandler={getErrorHandler('')}
            selectedIcon={iconFlag}>
            {appContext.availableLanguages?.map(value => {
              const langId = value.split('-')[0];
              return (
                <option key={value} value={value}>
                  {intl.formatMessage({ id: `language.${langId}` })}
                </option>
              );
            })}
          </Select>
        </div>
        <div className="contact">
          <div className="heading">
            <div className="help-title">
              <FormattedMessage id="footer.contact.heading" />
            </div>
            <div className="help-section">
              <span>
                  <FormattedMessage id="footer.contact.phone" />
              </span>
              <span>
                  <FormattedMessage id="footer.contact.description" />
              </span>
              <span className="contact-email">
                  <a href="mailto:consumercare@scotch-soda.com">
                    <FormattedMessage id="footer.contact.email" />
                  </a>
              </span>
            </div>
          </div>
          <div className="content">
          <div className="downloadup-section">
            <div className="downloadapp-title">
              <FormattedMessage id="footer.downloadapp.title" />
            </div>
            <div className="info">
                <a
                  href="https://play.google.com/store/apps/details?id=com.scotchsoda.highstreet.app"
                  target="_blank"
                  rel="noopener noreferrer">
                   <FormattedMessage id="footer.downloadapp.android" />
                </a>
                <a
                  href="https://apps.apple.com/app/scotch-soda-amsterdam-couture/id1062215070?l=en"
                  rel="noopener noreferrer">
                  <FormattedMessage id="footer.downloadapp.ios" />
                </a>
            </div>
          </div>
          <div className="followus-section">
            <div className="followus-title">
              <FormattedMessage id="footer.followus.title" />
            </div>
            <div className="info">
              <a
                href="https://www.facebook.com/ScotchSoda/"
                target="_blank"
                rel="noopener noreferrer">
                <img src={facebookLogo} alt="Facebook" />
              </a>
              <a
                href="https://www.instagram.com/scotch_soda/"
                rel="noopener noreferrer">
                <img src={instagramLogo} alt="Instagram" />
              </a>
              <a
                href="https://www.pinterest.com/scotch_soda/"
                rel="noopener noreferrer">
                <img src={pinterestLogo} alt="Pinterest" />
              </a>
              <a href="https://twitter.com/scotch_soda" rel="noopener noreferrer">
                <img src={twitterLogo} alt="Twitter" />
              </a>
              <a
                href="https://www.youtube.com/user/ScotchSodaOfficial"
                rel="noopener noreferrer">
                <img src={youtubeLogo} alt="Youtube" />
              </a>
              <a
                href="https://www.linkedin.com/company/scotch-&-soda/mycompany/verification/"
                rel="noopener noreferrer">
                <img src={linkedinLogo} alt="LinkedIn" />
              </a>
              <a
                href="https://www.tiktok.com/@scotch_soda"
                rel="noopener noreferrer">
                <img src={tiktokLogo} alt="TikTok" />
              </a>
            </div>
          </div>
            <div className="disclaimer">
              <div className="links">
                <a
                  href={privacyLinks[appContext.locale] ?? privacyLinks.default}
                  rel="noopener noreferrer">
                  <FormattedMessage id="footer.privacy" />
                </a>
                <a
                  href={cookieLinks[appContext.locale] ?? cookieLinks.default}
                  rel="noopener noreferrer">
                  <FormattedMessage id="footer.cookies" />
                </a>
                <a
                  href={termsLinks[appContext.locale] ?? termsLinks.default}
                  rel="noopener noreferrer">
                  <FormattedMessage id="footer.general-terms" />
                </a>
              </div>
              <div className="copyright">
                <FormattedMessage id="footer.copyright" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
