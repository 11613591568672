import EvaService from './EvaService';

const QRCodeService = {
  getBarcodeUrl: async (value: string) => {
    const assetsUrl = await EvaService.getAssetsUrl();

    return `${assetsUrl}/barcode/qr/124/124/USER:CUSTOM:${value}/no-margin`;
  }
};

export default QRCodeService;
