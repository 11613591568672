import React, { useEffect } from 'react';
import Layout from 'components/shared/Layout';
import Header from 'components/shared/header/Header';
import PageContent from 'components/shared/PageContent';
import { FormattedMessage } from 'react-intl';
import MemberCard from './MemberCard';
import AppleWallet from './AppleWallet';

export default function Member(): JSX.Element {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <Header />
      <PageContent className="member">
        <div className="info">
          <div><FormattedMessage id="member.heading" /></div>
          <div><FormattedMessage id="member.heading-second" /></div>
        </div>
        <MemberCard />
        <AppleWallet />
      </PageContent>
    </Layout>
  );
}
