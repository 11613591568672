import React, { useEffect, useState } from 'react';
import useContentfulEntries from 'services/contentful/useContentfulHooks';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import ScotchLogo from '../../assets/images/logo.svg';
import QRCode from './QRCode';

export default function MemberCard(): JSX.Element {
  const [url, setMemberContent] = useState<string>();
  const [assets] = useContentfulEntries();
  const member  = useParams<{id: string}>();

  useEffect(() => {
    const assetTitle = 'SS_member';
    if (assets && assets?.items && assets.items.length) {
      assets.items.map((asset) => {
        if (asset.fields.title === assetTitle) {
          setMemberContent(asset.fields.file.url);
        }
        return asset;
      });
    } else {
     setMemberContent('');
    }
  }, [assets])

  return (
    <div className="member-card-container">
      <div className="member-card">
        <div className='member-card-title'>
          <img src={ScotchLogo} alt=''/> 
          <p className="title-logo"><FormattedMessage id="member-card.title" /></p>
        </div>
        { url &&
          <div className="member-card-image" style={{backgroundImage:"url(" + url + ")"}}>
            <div className='member-card-info'></div>
          </div>
        }
        <div className="member-card-id">
            <div className="title"><FormattedMessage id="member-card.id" /></div>
            <div className="memberid">{member.id}</div>
        </div>
        <div className="member-card-qrcode">
            {member.id && <QRCode value={member.id} />}
        </div>
      </div>
    </div>
  );
}
