import LandingPage from 'components/landingPage/LandingPage';
import Member from 'components/member/Member';
import Success from 'components/member/Onboarding';
import Register from 'components/register/Register';
import CountryRouter from 'components/shared/CountryRouter';
import CountrySwitch from 'components/shared/CountrySwitch';
import PrivateRoute from 'components/shared/PrivateRoute';
import { first, last } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import ApplicationRoutes from 'Routes';
import { AppContext, DefaultAppContext, IAppContext } from 'services/appContext/AppContext';
import AppContextUtil from 'services/appContext/AppContextUtil';
import useCountryService from './services/eva/CountryService';

function App(): JSX.Element {
  // Get the country code from the entry url. expected in the first url segement
  const pathname = window.location.pathname;
  const [, countryOnEntry] = window.location.pathname.split('/');
  const { search } = window.location;
  const params = new URLSearchParams(search);

  let countryParam: string | undefined;
  let languageParam: string | undefined;

 //culture exist in the pathname
  if(pathname.includes('culture')) {
    const culture = pathname.substring(pathname.indexOf('culture'))?.split('/');
    const splitCountryParam = culture[culture.indexOf('culture') + 1 ]?.split('_');
    countryParam = last(splitCountryParam)?.toLowerCase();
    languageParam = first(splitCountryParam)?.toLowerCase();
  }

  //culture exist in url param
  if(params.get('culture')?.includes('_')) {
    const splitCountryParam = params.get('culture')?.split('_');
    countryParam = last(splitCountryParam)?.toLowerCase();
    languageParam = first(splitCountryParam)?.toLowerCase();
  }

  // get the confguration from localStorage
  const appConfig = AppContextUtil.getFromLocalStorage();

  const countryService = useCountryService();

  // get countryInfo for requested country
  const countryInfo = countryService.getCountryById(
     countryOnEntry
  );

  if (countryInfo?.endpoint) {
    appConfig.endpointKey = countryInfo.endpoint;
  } else {
    appConfig.endpointKey = undefined;
  }

  if (countryInfo) {
    // if the requested country is different than the stored config, reset the usertoken and language
    if (appConfig.env !== countryInfo.id) {
      appConfig.userToken = undefined;
    }

    appConfig.env = countryInfo.id;
    appConfig.countryID = countryParam || DefaultAppContext.countryID;
    appConfig.availableLanguages = [];
    appConfig.authenticationToken = countryInfo.token;
    appConfig.language = languageParam || DefaultAppContext.language;

    const orderIdParam = params.get('orderId');
    if (orderIdParam && Number(orderIdParam) > 0) {
      appConfig.orderId = Number(orderIdParam);
    }

    const storeIdParam = params.get('storeId');
    if (storeIdParam && Number(storeIdParam) > 0) {
      appConfig.storeId = Number(storeIdParam);
    }
  }

  appConfig.locale = `${appConfig.language.toLowerCase()}-${appConfig.env.toUpperCase()}`;

  const [appContext, setAppContext] = useState(appConfig);

  const appContextWrapper = useMemo(() => ({ appContext, setAppContext }), [
    appContext,
    setAppContext
  ]);

  const getCountries = useCallback(
    (token: string) => {
      return countryService
        .getApplicationConfigurationForCountry(token)
        .then(result => {
          const cultures: {
            LanguageID: string;
            CountryID: string;
            Culture: string;
          }[] = result?.Configuration['Cultures'];
          const userRequirements: EVA.Core.UserRequirementFor[] =
            result?.Configuration['UserRequirements'];

          const availableLanguages = cultures.map(culture => {
            return culture.LanguageID.toLowerCase();
          });

          const newContext: IAppContext = {
            ...appContext,
            availableLanguages,
            userRequirements,
            currencyID: result?.Configuration['CurrencyID']
          };

          // if the current language is available
          if (!availableLanguages.includes(appContext.language)) {
            const defaultLangForCountry = first(availableLanguages);
            if (defaultLangForCountry) {
              newContext.language = defaultLangForCountry;
            }
          }
          return newContext;
        });
    },
    [appContext, countryService]
  );

  useEffect(() => {
    AppContextUtil.saveToLocalStorage(appContext);
  }, [appContext]);

  useEffect(() => {
    async function asyncGetAvailableLanguages(token: string) {
      const appContextWithLanguages = await getCountries(token);
      setAppContext(appContextWithLanguages);
    }

    AppContextUtil.saveToLocalStorage(appContext);

    if (
      appContext.availableLanguages.length === 0 &&
      appContext.authenticationToken
    ) {
      asyncGetAvailableLanguages(appContext.authenticationToken);
    }

  }, [appContext, getCountries]);

  return (
    <AppContext.Provider value={appContextWrapper}>
      <CountryRouter RouterComponent={BrowserRouter}>
        <CountrySwitch>
          <Route path={ApplicationRoutes.register}>
            <Register />
          </Route>
          <Route path={ApplicationRoutes.member}>
            <Member />
          </Route>
          <PrivateRoute path={ApplicationRoutes.sucess}>
            <Success />
          </PrivateRoute>
          <Route path={ApplicationRoutes.home}>
            <LandingPage />
          </Route>
        </CountrySwitch>
      </CountryRouter>
    </AppContext.Provider>
  );
}

export default App;
