import Button from "components/shared/form/Button";
import React, { useEffect, useState } from "react";
import Modal from 'react-modal';
import Toggle from 'react-toggle';
import useUserService from 'services/eva/UserService';
import {RadioGroup, Radio} from 'react-radio-group';
import CheckboxGroup from 'react-checkbox-group';
import { useHistory } from "react-router-dom";
import Input from "components/shared/form/Input";
import Textarea from "components/shared/form/TextareaInputField";
import { FormattedMessage } from "react-intl";

const InquiriesDialog = (props: {
  inquiry: any;
  userID: number;
  index: number;
  showDialog?: boolean;
  loading?: boolean;
  nrOfInquiries: number;
  callbackValue?: (value: boolean) => void;
  duplicateCardFunc?: Function;
}) => {

  const userService = useUserService();
  const history = useHistory();
  const [modalIsOpen, setIsOpen] = React.useState() as any;
  const [booleanFields, setBooleanFields] = useState([]) as any;
  const [enumNotArrayFields, setEnumNotArrayFields] = useState([]) as any;
  const [enumIsArrayFields, setEnumIsArrayFields] = useState([]) as any;
  const [stringValues, setStringValues] = useState([]) as any;
  const [numberValues, setNumberValues] = useState([]) as any;
  const [dateValues, setDateValues] = useState([]) as any;

  useEffect(() =>  {
    if(props.showDialog === true){
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [props, setIsOpen]);

  function closeModal() {
    setIsOpen(false);
    history.push('/success');
  }
  
  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const items = [] as any;

    stringValues.map((values:any) => {
      if (values) {
        var StringValues: any;
        Object.entries(values).forEach(([key, value]) => {
          if(key !== 'ID'){
            StringValues = {
              ID: values.ID,
              Value: {
                StringValue: value
              }
          }
          }
        });
      }
      items.push(StringValues);
      return null;
    });

    numberValues.map((values:any) => {
      if (values) {
        var NumberValues: any;
        Object.entries(values).forEach(([key, value]) => {
          if(key !== 'ID'){
            NumberValues = {
              ID: values.ID,
              Value: {
                NumberValue: Number(value)
              }
          }
          }
        });
      }
      items.push(NumberValues);
      return null;
    });

    dateValues.map((values:any) => {
      if (values) {
        var DateValues: any;
        Object.entries(values).forEach(([key, value]) => {
          if(key !== 'ID'){
            DateValues = {
              ID: values.ID,
              Value: {
                DateTimeValue: value
              }
          }
          }
        });
      }
      items.push(DateValues);
      return null;
    });

    booleanFields.map((values:any) => {
      if (values) {
        var BoolValues: any;
        Object.entries(values).forEach(([key, value]) => {
          if(key !== 'ID'){
            BoolValues = {
              ID: values.ID,
              Value: {
                BoolValue: value
              }
          }
          }
        });
      }
      items.push(BoolValues);
      return null;
    });

    enumNotArrayFields.map((values:any) => {
      if (values) {
        var StringValues: any;
        Object.entries(values).forEach(([key, value]) => {
          if(key !== 'ID'){
            StringValues = {
              ID: values.ID,
              Value: {
                StringValue: value
              }
          }
          }
        });
      }
      items.push(StringValues);
      return null;
    });

    enumIsArrayFields.map((values:any) => {
      var stringArrayValues;
      const enumCheckBoxArray: any = [];
      if (values) {
        values['CheckBoxGroupValue-'+  values.ID].map((x:any) => {
          stringArrayValues = {
              ID: values.ID,
              Value: {
                ArrayValues: enumCheckBoxArray
              }
          }
          enumCheckBoxArray.push({'StringValue': x});
          return null;
        });
      }
      items.push(stringArrayValues);
      return null;
    });

    if (items.length > 0) {
      await userService.submitInquiry(props.userID, props.inquiry.Inquiry.ID ,items);
    } else {
      history.push('/success');
    }

    if(props.index === props.nrOfInquiries) {
      history.push('/success');
    } 
   
  };

  const handleBooleanFormChange = (event: any, param: any) => {
    const objToogleField = {
      [event.target.name] : event.target.checked,
      'ID' : param.ID
    };

    const foundedElement = booleanFields.find((x:any) => x.ID === param.ID);
    if (!foundedElement) {
      setBooleanFields( () => [...booleanFields, objToogleField]);
    } else {
      const arraywithoutFoundedElement = booleanFields.map(
        (obj:any) => (obj.ID === param.ID ? { ...obj, [event.target.name]: event.target.checked } : obj),
      );
      setBooleanFields( () => [...arraywithoutFoundedElement]);
    }

  }

  const handleEnumCheckBoxFormChange = (event: any, param: any) => {
    var array = [] as any;
    var elementToPush = [];

    if( typeof event === 'string' || event instanceof String) {
      array.push(event);
      elementToPush = array;
    } else {
      elementToPush = event;
    }

    var initArray = {
      ['CheckBoxGroupValue-'+  param.ID]:  elementToPush,
      'ID': param.ID
    }

    const foundedElement = enumIsArrayFields.find((x:any) => x.ID === param.ID);
    if (!foundedElement) {
          setEnumIsArrayFields( () => [...enumIsArrayFields, initArray]);
      } else {
        const arraywithoutFoundedElement = enumIsArrayFields.map(
          (obj:any) => (obj.ID === param.ID ? { ...obj, ['CheckBoxGroupValue-'+ param.ID]: event } : obj),
        );
        setEnumIsArrayFields(arraywithoutFoundedElement);
      }
};

  const handleValue = (event: any, param: any, keyField:any, arrayValues: any, setValues: any) => {
    if (arrayValues) {
      const elementEvent = keyField === 'RadioValue-' ? event : event.target.value; 
      const stringField = {
        [keyField + param.ID]: elementEvent ,
        'ID': param.ID
      }

      const foundedElement = arrayValues.find((x:any) => x.ID === param.ID);
      if (!foundedElement) {
        setValues( () => [...arrayValues, stringField]);
      } else {
        const arraywithoutFoundedElement = arrayValues.map(
          (obj:any) => (obj.ID === param.ID ? { ...obj, [keyField + param.ID]: elementEvent } : obj),
        );
        setValues( () => [...arraywithoutFoundedElement]);
    }
    }
  };

  const checkStringElement = (param: any,  keyField:any, arrayValues: any) => {
    if (arrayValues) {
      var toogleFieldValue = arrayValues.find((s:any)=>s.ID === param.ID);
      if (!toogleFieldValue) {
        return '';
      } else {
        var field = toogleFieldValue[keyField + param.ID];
        return field;
      }
  } else return '';
  }  

  const checkBooleanElement = (param: any) => {
    if (booleanFields) {
      var toogleFieldValue = booleanFields.find((s:any)=>s.ID === param.ID);
      if (!toogleFieldValue) {
        return false;
      } else {
        var field = toogleFieldValue['BoolValue-' + param.ID];
        return field;
      }
  } else return false;
  }

  const checkEnumIsArrayElement = (param: any) => {
    if (enumIsArrayFields) {
        var checkFieldObjs = enumIsArrayFields.find((s:any)=>s.ID === param.ID);
        if(checkFieldObjs){
          var arrayOfValues = checkFieldObjs['CheckBoxGroupValue-' + param.ID];
          return arrayOfValues;
        }else return [];
    } else return [];
  }

  function renderSwitch(param: any, index:any) {
    switch(param.CustomField.DataType) {
      case 'Bool':
        return <label  key={index} className="padding-20">
            <Toggle
              checked={checkBooleanElement(param)}
              name={'BoolValue-'+ param.ID}
              onChange={event => handleBooleanFormChange(event, param)}
              />
          </label>
      case 'Enum':
        return (
          <>
                {param.CustomField.IsArray === true ? (
                  <CheckboxGroup name={'CheckBoxValue-'+ param.ID}  
                                value={checkEnumIsArrayElement(param)}  
                                onChange={event => handleEnumCheckBoxFormChange(event, param)  }
                                key={'CheckBoxValue-'+ param.ID}
                                >
                  {(Checkbox) => (
                    <>
                      {Object.keys(param.CustomField.Options.EnumValues).map(function (key, index) {
                          return <div key={index} className="padding-20">
                            <Checkbox value={key}/> 
                            {param.CustomField.Options.EnumValues[key]}
                        </div>
                      }
                    )}
                    </>
                  )}
                  </CheckboxGroup>
                ) : (
                     <RadioGroup 
                        name={'RadioValue-'+ param.ID}   
                        selectedValue={checkStringElement(param, 'RadioValue-', enumNotArrayFields )}
                        onChange={event => handleValue(event, param, 'RadioValue-', enumNotArrayFields , setEnumNotArrayFields)} 
                        key={'RadioValue-'+ param.ID} 
                      >
                        {Object.keys(param.CustomField.Options.EnumValues).map(function (key, index) {
                              return <div key={index} className="padding-20">
                                        <Radio value={key} /> 
                                        {param.CustomField.Options.EnumValues[key] }
                                    </div>
                          }
                        )}
                      </RadioGroup>
                )}
          </>
        );
      case 'String':
        return (
          <Input
            type="text"
            name={'StringValue-'+ param.ID}
            value={checkStringElement(param, 'NumberValue-', numberValues)}
            onChange={event => handleValue(event, param, 'NumberValue-', numberValues, setNumberValues)}
          />
        );
        case 'Text':
          return (
            <Textarea 
             name={'TextAreaValue-'+ param.ID} 
             value={checkStringElement(param,'TextAreaValue-', stringValues)}
             onChange={event => handleValue(event, param, 'TextAreaValue-', stringValues, setStringValues)}
              />
          );
      case 'Date':
          return (
            <Input 
              type="inquiry-date"  
              name={'DateValue-'+ param.ID}
              value={checkStringElement(param, 'DateValue-', dateValues)}
              onChange={event => handleValue(event, param, 'DateValue-', dateValues, setDateValues)}
              />
            );
      case 'Time':
          return (
          <Input 
            type="datetime-local"  
            name={'DateTimeValue-'+ param.ID}
            value={checkStringElement(param, 'DateTimeValue-', dateValues)}
            onChange={event => handleValue(event, param, 'DateTimeValue-', dateValues, setDateValues)}
            />
          );
      case 'Integer':
        return (
          <Input
            type="number"
            name={'NumberValue-'+ param.ID}
            value={checkStringElement(param, 'NumberValue-', numberValues)}
            onChange={event => handleValue(event, param, 'NumberValue-', numberValues, setNumberValues)}
          />
        );
      case 'Decimal':
          return (
            <Input
            type="decimal"
            name={'DecimalValue-'+ param.ID}
            step="0.01"
            min="0.00"
            placeholder="0.00"
            value={checkStringElement(param, 'DecimalValue-', numberValues)}
            onChange={event => handleValue(event, param, 'DecimalValue-', numberValues, setNumberValues)}
          />
          );
      default:
        return 'no data type implemented';
    }
  }

  return (
    <>
    <Modal
        isOpen={modalIsOpen}
        className="inquiry-modal"
        ariaHideApp={false}
      >
        {Object.keys(props.inquiry).length !== 0 && (
          <>
           <h1 className="inquiry-title" title={props.inquiry.Inquiry.Name}>{props.inquiry.Inquiry.Name}</h1>
              <form onSubmit={submitHandler}>
                <div className="inquiry-data">
                  {props.inquiry.Inquiry.Items.map((inquiry: any, index: any) => ( 
                                  <div key={index} className="inquiry-container">
                                    <div className="inquiry-question">
                                    {inquiry.Position}. {inquiry.Name}
                                    </div>
                                      {renderSwitch(inquiry, index)}
                                  </div>
                    ))}
                </div>
                <div className="buttons-inquiries">
                <div className="form-row-button-inquiries">
                  <button className="skip-inquiries" onClick={closeModal}>
                    <FormattedMessage id={"btn.skip.inquiries"} />
                  </button>
                </div>
                 { props.nrOfInquiries > 1 && <div className="footer-nrinquiries">{props.index} of {props.nrOfInquiries}</div> }
                <Button
                  type="submit"
                  textId="btn.save.inquiries"
                />
                </div>
              </form> 
          </>
        )}
      </Modal>
    </>
  );
};

export default InquiriesDialog;
