import { useContext, useCallback } from 'react';
import { AppContext } from 'services/appContext/AppContext';
import { Core } from '@springtree/eva-services-core';
import { RegisterModel } from 'components/register/RegisterModel';
import moment from 'moment';
import EvaEndpointProvider from './EvaEndpointProvider';

function useUserService() {
  const { appContext } = useContext(AppContext);

  const getDate = useCallback((date: string) => {
    if (date) {
        return moment(date, 'DD/MM/YYYY').format();
    }

    return undefined;
  }, []);

  const login = useCallback(
    async (email: string, password: string) => {
      const evaEndpoint = await EvaEndpointProvider.getEndpoint();

      return evaEndpoint.callService(
        Core.Login,
        {
          Username: email,
          Password: password,
          UserType: 2,
          AsEmployee: false
        },
        {
          authenticationToken: appContext.authenticationToken
        }
      );
    },
    [appContext]
  );

  const checkEmailAddressAvailability = useCallback(
    async (email: string): Promise<boolean> => {
      const evaEndpoint = await EvaEndpointProvider.getEndpoint();

      const response = await evaEndpoint.callService(
        Core.CheckEmailAddressAvailability,
        {
          EmailAddress: email
        },
        {
          authenticationToken: appContext.authenticationToken
        }
      );

      return response !== undefined && response.IsAvailable;
    },
    [appContext]
  );

  const getUserInfo = useCallback(async () => {
    const evaEndpoint = await EvaEndpointProvider.getEndpoint();

    return evaEndpoint.callService(Core.GetCurrentUser, undefined, {
      authenticationToken:
        appContext.userToken || appContext.authenticationToken
    });
  }, [appContext]);

  const register = useCallback(
    async (model: RegisterModel) => {
      const evaEndpoint = await EvaEndpointProvider.getEndpoint();

      return evaEndpoint.callService(
        Core.CreateCustomer,
        {
          User: {
            EmailAddress: model.email,
            FirstName: model.firstName,
            LastName: model.lastName,
            LanguageID: appContext.language,
            DateOfBirth: getDate(model.dateOfBirth),
            PreferredStoreID: appContext.storeId,
            CountryID: appContext.countryID?.toUpperCase(),
            PhoneNumber: model.phoneNumber,
          },
          AutoLogin: true,
          AccountType: 1
        },
        {
          authenticationToken: appContext.authenticationToken
        }
      );
    },
    [appContext.authenticationToken, appContext.countryID, appContext.language,appContext.storeId, getDate]
  );

  const subscribe = useCallback(
    async (userId: number, subscriptionId: number) => {
      const evaEndpoint = await EvaEndpointProvider.getEndpoint();

      await evaEndpoint.callService(
        Core.SubscribeUser,
        {
          UserID: userId,
          Subscriptions: [
            {
              SubscriptionID: subscriptionId,
            }
          ]
        },
        {
          authenticationToken:
            appContext.userToken || appContext.authenticationToken
        }
      );
    },
    [appContext.authenticationToken, appContext.userToken]
  );

  const subscribeToAll = useCallback(
    async (userId: number,  emailOffers: boolean, smsOffers: boolean) => {
      const evaEndpoint = await EvaEndpointProvider.getEndpoint();

      const newsletters = await evaEndpoint.callService(
        Core.GetAvailableSubscriptions,
        undefined,
        {
          authenticationToken:
            appContext.userToken || appContext.authenticationToken
        }
      );

      if (newsletters) {
        if(emailOffers) {
          newsletters.Subscriptions.map( async newsLetter => {
            if(newsLetter.ID && newsLetter.BackendID && newsLetter.BackendID.includes('NEWSLETTER')) {
                await subscribe(userId, newsLetter.ID);
            }
          });
        }
        if (smsOffers) {
          newsletters.Subscriptions.filter(x => x.BackendID === 'SMS_UPDATES').map(
            async subscription => {
              if (subscription.ID) {
                await subscribe(userId, subscription.ID);
              }
            }
          );
        }
      }

      return newsletters;
 
    },
    [appContext.authenticationToken, appContext.userToken, subscribe]
  );

  const getSubscriptions = useCallback(
    async (userId: number) => {
      const evaEndpoint = await EvaEndpointProvider.getEndpoint();

      return evaEndpoint.callService(
        Core.GetUserSubscriptions,
        { UserID: userId },
        {
          authenticationToken:
            appContext.userToken || appContext.authenticationToken
        }
      );
    },
    [appContext]
  );

  const getInquiries = useCallback(
    async (inquiryId: number) => {
      const evaEndpoint = await EvaEndpointProvider.getEndpoint();

      return evaEndpoint.callService(
        Core.GetInquiry,
        { ID: inquiryId },
        {
          authenticationToken:
            appContext.userToken || appContext.authenticationToken
        }
      );
    },
    [appContext]
  );

  const submitInquiry = useCallback(
    async (userId: number, inquiryId: number, items: any) => {
      const evaEndpoint = await EvaEndpointProvider.getEndpoint();

      await evaEndpoint.callService(
        Core.SubmitInquiry,
        {
          UserID: userId,
          InquiryID: inquiryId,
          Items: items
        },
        {
          authenticationToken:
            appContext.userToken || appContext.authenticationToken
        }
      );
    },
    [appContext]
  );

  const requestPasswordResetToken = useCallback(
    async (email: string, locale: string) => {
      const evaEndpoint = await EvaEndpointProvider.getEndpoint();

      return evaEndpoint.callService(
        Core.RequestPasswordResetToken,
        { EmailAddress: email },
        { authenticationToken: appContext.authenticationToken }
      );
    },
    [appContext]
  );

  const attachUserToOrder = useCallback(
    async (userId: number, orderId: number) => {
      const evaEndpoint = await EvaEndpointProvider.getEndpoint();

      return evaEndpoint.callService(
        Core.AttachCustomerToOrder,
        { OrderID: orderId, UserID: userId },
        { authenticationToken: appContext.authenticationToken }
      );
    },
    [appContext.authenticationToken]
  )

  return {
    login,
    checkEmailAddressAvailability,
    getUserInfo,
    register,
    subscribeToAll,
    getSubscriptions,
    requestPasswordResetToken,
    attachUserToOrder,
    getInquiries,
    submitInquiry,
  };
}

export default useUserService;
