import React from 'react';

export type IAppContext = {
  userId: number;
  email: string;
  locale: string;
  env: string;
  language: string;
  currencyID: string;
  availableLanguages: string[];
  userRequirements?: EVA.Core.UserRequirementFor[];
  authenticationToken?: string;
  userToken?: string;
  orderId?: number;
  storeId?: number;
  endpointKey?: string;
  countryID?: string;
};

export const DefaultAppContext: IAppContext = {
  userId: 0,
  locale: 'en-EN',
  language: 'en',
  env: 'us',
  countryID: 'ca',
  currencyID: 'EUR',
  email: '',
  availableLanguages: ['en', 'fr', 'es']
};

type AppContextWrapper = {
  appContext: IAppContext;
  setAppContext: (newAppContext: IAppContext) => unknown;
};

export const AppContext = React.createContext<AppContextWrapper>({
  appContext: DefaultAppContext,
  setAppContext: () => {}
});
