import React, { useEffect } from 'react';
import Layout from 'components/shared/Layout';
import Header from 'components/shared/header/Header';
import PageContent from 'components/shared/PageContent';
import SuccessCard from './SuccessPage';

export default function Member(): JSX.Element {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Layout>
      <Header />
      <PageContent className="member">
        <SuccessCard />
      </PageContent>
    </Layout>
  );
}
