/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { useIntl, FormattedMessage } from 'react-intl';
import classNames from 'classnames';
import { ErrorHandler } from 'services/errorHandling/useErrors';
import NumberFormat from 'react-number-format';
import ErrorMessage from './ErrorMessage';

type Props = {
  titleId?: string;
  placeholderId?: string;
  value?: string | number;
  name?: string;
  step?: string;
  min?: string;
  placeholder?: string;
  defaultValue?: string | number;
  type: 'text' | 'email' | 'date' | "number" | "decimal" | "inquiry-date" | "datetime-local";
  autoComplete?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  errorHandler?: ErrorHandler;
  optional?: boolean;
};

export default function Input(props: Props): JSX.Element {
  const intl = useIntl();
  const errors = props.errorHandler && props.errorHandler.getErrors();

  return (
    <div className="form-row">
      <div className="label">
        <span>
          {props.titleId && <FormattedMessage id={props.titleId} />}
        </span>
        {props.optional && (
          <span>
            <FormattedMessage id="optional" />
          </span>
        )}
      </div>
      {props.type === 'date' && (
        <div className="date-info">
          <FormattedMessage id="form.date-of-birth.info" />
        </div>
      )}
      {errors && <ErrorMessage errors={errors} />}
      { props.type === 'date' && (
        <NumberFormat
          value={props.value}
          placeholder={
            props.placeholderId &&
            intl.formatMessage({
              id: props.placeholderId
            })
          }
          format="##/##/####"
          mask={['D', 'D', 'M', 'M', 'Y', 'Y', 'Y', 'Y']}
          className={classNames('input', errors ? 'input-error' : undefined)}
          onChange={props.onChange}
          onBlur={props.onBlur}
        />
      )
      }
      { (props.type === 'text' || props.type === 'email') && (
        <input
          className={classNames(
            'input',
            errors ? 'input-error' : undefined,
          )}
          type={props.type}
          value={props.value}
          defaultValue={props.defaultValue}
          onChange={props.onChange}
          onBlur={props.onBlur}
          autoComplete={props.autoComplete}
          placeholder={
            props.placeholderId &&
            intl.formatMessage({
              id: props.placeholderId
            })
          }
        />
      )}
      {props.type === 'number' && (
        <input
            type='number'
            className={classNames(
              'input',
            )}
            value={props.value}
            onChange={props.onChange}
          />
      )}
      {props.type === 'decimal' && (
        <input
            type='number'
            className={classNames(
              'input',
            )}
            value={props.value}
            step={props.step}
            min={props.min}
            placeholder={props.placeholder}
            onChange={props.onChange}
          />
      )}
      {props.type === 'inquiry-date' && (
        <input
            type='date'
            className={classNames(
              'input',
            )}
            value={props.value}
            name={props.name}
            onChange={props.onChange}
          />
      )}
    </div>
  );
}
