/* eslint-disable jsx-a11y/alt-text */
import classNames from 'classnames';
import React from 'react';

type Props = {
  value?: string | number;
  name?: string;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
};

export default function Textarea(props: Props): JSX.Element {

  return (
    <div className="form-row">
          <textarea
          {...props}
          value={props.value}
          name={props.name}
          className={classNames(
            'textarea'
          )}
         >
        </textarea>
    </div>
  );
}
