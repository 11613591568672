import {DefaultAppContext, IAppContext } from './AppContext';

const localStorageInfoKey = 'crm.appContext';

export type LocalStorageAppContext = {
  email: string;
  authenticationToken?: string;
  userToken?: string;
  env?: string;
  language?: string;
  currencyID?: string;
  locale?: string;
  endpointKey?: string;
};

export const DefaultLocalStorageAppContext: LocalStorageAppContext = {
  email: ''
};

const AppContextUtil = {
  saveToLocalStorage: (appContext: IAppContext) => {
    const localStorageAppContext: LocalStorageAppContext = {
      email: appContext.email,
      authenticationToken: appContext.authenticationToken,
      userToken: appContext.userToken,
      env: appContext.env,
      currencyID: appContext.currencyID,
      language: appContext.language,
      locale: appContext.locale,
      endpointKey: appContext.endpointKey,
    };

    localStorage.setItem(
      localStorageInfoKey,
      JSON.stringify(localStorageAppContext)
    );
  },

  getFromLocalStorage: (countryId?: string): IAppContext => {
    let appContext: IAppContext = DefaultAppContext;
    const localStorageData = localStorage.getItem(localStorageInfoKey);

    if (localStorageData) {
      const localStorageAppContext: LocalStorageAppContext = JSON.parse(
        localStorageData
      );

      appContext = { ...appContext, ...localStorageAppContext };
    }

    return appContext;
  }
};

export default AppContextUtil;
