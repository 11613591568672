import { createClient, ContentfulClientApi, AssetCollection } from 'contentful';
import { useState, useCallback, useEffect } from 'react';
import AppContextUtil from 'services/appContext/AppContextUtil';
import useCountryService from 'services/eva/CountryService';

export type TUseContentfulAssets = [AssetCollection | undefined, boolean];

const useContentfulEntries = (): TUseContentfulAssets => {
  const [data, setData] = useState<AssetCollection>();
  const [loading, setLoading] = useState(false);
  const [contentfulClient, setContentfulClient] = useState<ContentfulClientApi>();
  const countryService = useCountryService();

  const getAppConfiguration = useCallback(() => {
    const appConfig = AppContextUtil.getFromLocalStorage();
    const token = appConfig.authenticationToken as string;
    if ( typeof token !== "undefined" ) {
      countryService
      .getApplicationConfigurationForCountry(token)
      .then(result => {
        const spaceId =  result?.Configuration['Contentful:SpaceID'];    
        const accessToken = result?.Configuration['Contentful:AccessToken'];

        const client = createClient({
          space:  spaceId,
          accessToken,
        });
  
        setContentfulClient(client);
        if(client) {
          client.getAssets().then((assets) => {
            setData(assets);
            setLoading(false);
          }).catch(e => {
            setLoading(false);
          });
        }
      });
    }
  }, [countryService]);

  useEffect(() => {
    if (typeof contentfulClient === "undefined") {
      getAppConfiguration();
    }

  }, [contentfulClient, getAppConfiguration]);

  return [data, loading];
};

export default useContentfulEntries;
