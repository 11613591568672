import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { AppContext } from 'services/appContext/AppContext';
import translations from 'translations';

export interface ICountryRouterProps {
  RouterComponent: React.ComponentClass<any>;
}

export const CountryRouter: React.FC<ICountryRouterProps> = ({
  RouterComponent,
  children
}: React.PropsWithChildren<ICountryRouterProps>) => {
  const { appContext } = useContext(AppContext);

  return (
    <RouterComponent>
      <Route path="/:env([a-z]{2})">
        {(location: any) => {
          /**
           * If countrty is not in route path, add default to path and redirect
           */
          if (!location.location.pathname.includes(`/${appContext.env}/`)) {
            return (
              <Redirect
                to={`/${appContext.env}${location.location.pathname}${location.location.search}`}
              />
            );
          }

          return (
            <IntlProvider
              locale={appContext.language}
              messages={translations[appContext.language]}>
              {children}
            </IntlProvider>
          );
        }}
      </Route>
    </RouterComponent>
  );
};

export default CountryRouter;
