import React, { useEffect, useState } from 'react';
import QRCodeService from 'services/eva/QRCodeService';
import { useIntl } from 'react-intl';

type Props = {
  value: string;
};

export default function Barcode(props: Props): JSX.Element {
  const intl = useIntl();
  const [barcodeUrl, setBarcodeUrl] = useState<string>();

  useEffect(() => {
    QRCodeService.getBarcodeUrl(props.value).then(url => setBarcodeUrl(url));
  }, [props.value]);

  return (
    <div className="qrcode-container">
      {barcodeUrl && (
        <img src={barcodeUrl} alt={intl.formatMessage({ id: 'my-company' })} />
      )}
    </div>
  );
}
