import React, { ReactNode, useState, useEffect } from 'react';
import useContentfulEntries from 'services/contentful/useContentfulHooks';
import { Link } from 'react-router-dom';

type Props = {
  children?: ReactNode;
  backButtonLink?: string;
};


export default function Header(props: Props): JSX.Element {

  
const [url, setMemberContent] = useState<any>();
const [assets] = useContentfulEntries();


useEffect(() => {
  if (assets && assets?.items && assets.items.length) {
    assets.items.map((asset) => {
      if (asset.fields.title === 'SS_logo') {
        setMemberContent(asset.fields.file.url);
      }
      return asset;
    });
  } else {
   setMemberContent(null);
  }
}, [assets])

  return (
    <header>
      <div className="header">
        {props.backButtonLink &&
          <div className="navigation">
            <Link to={props.backButtonLink}>
              <div className="back-button" />
            </Link>
          </div>
        }
         { url &&
          <div className="logo" style={{backgroundImage:"url(" + url + ")"}}>
            <div className='member-card-info'></div>
          </div>
        }
        {props.children}
      </div>
    </header>
  );
}
