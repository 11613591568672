import en from './en.json';
import fr from './fr.json';
import nl from './nl.json';
import de from './de.json';
import es from './es.json';
import it from './it.json';
import sv from './sv.json';

const translations = {
  en,
  fr,
  nl,
  de,
  es,
  it,
  sv,
  
} as any;

export const termsLinks: any = {
  default: 'https://www.scotch-soda.com/ca/en/terms-and-conditions.html',
  'en-US': 'https://www.scotch-soda.com/ca/en/terms-and-conditions.html',
  'fr-US': 'https://www.scotch-soda.com/ca/fr/terms-and-conditions.html',
  'es-US': 'https://www.scotch-soda.com/ca/es/terms-and-conditions.html',
  'en-EU': 'https://www.scotch-soda.com/nl/en/terms-and-conditions.html',
  'nl-EU': 'https://www.scotch-soda.com/nl/nl/terms-and-conditions.html',
  'de-EU': 'https://www.scotch-soda.com/de/de/terms-and-conditions.html',
  'es-EU': 'https://www.scotch-soda.com/es/es/terms-and-conditions.html',
  'it-EU': 'https://www.scotch-soda.com/it/it/terms-and-conditions.html',
  'fr-EU': 'https://www.scotch-soda.com/fr/fr/terms-and-conditions.html',
  'sv-EU': 'https://www.scotch-soda.com/sv/sv/terms-and-conditions.html',
};

export const privacyLinks: any = {
  default: 'https://www.scotch-soda.com/ca/en/privacy-policy.html',
  'en-US': 'https://www.scotch-soda.com/ca/en/privacy-policy.html',
  'fr-US': 'https://www.scotch-soda.com/ca/fr/privacy-policy.html',
  'es-US': 'https://www.scotch-soda.com/ca/es/privacy-policy.html',
  'en-EU': 'https://www.scotch-soda.com/nl/en/privacy-policy.html',
  'nl-EU': 'https://www.scotch-soda.com/nl/nl/privacy-policy.html',
  'de-EU': 'https://www.scotch-soda.com/de/de/privacy-policy.html',
  'es-EU': 'https://www.scotch-soda.com/es/es/privacy-policy.html',
  'it-EU': 'https://www.scotch-soda.com/it/it/privacy-policy.html',
  'fr-EU': 'https://www.scotch-soda.com/fr/fr/privacy-policy.html',
  'sv-EU': 'https://www.scotch-soda.com/se/sv/privacy-policy.html',

};

export const cookieLinks: any = {
  default: 'https://www.scotch-soda.com/ca/en/privacy-policy.html',
  'en-US': 'https://www.scotch-soda.com/ca/en/privacy-policy.html',
  'fr-US': 'https://www.scotch-soda.com/ca/fr/privacy-policy.html',
  'es-US': 'https://www.scotch-soda.com/ca/es/privacy-policy.html',
  'en-EU': 'https://www.scotch-soda.com/nl/en/cookie-policy.html',
  'nl-EU': 'https://www.scotch-soda.com/nl/nl/cookie-policy.html',
  'de-EU': 'https://www.scotch-soda.com/de/de/cookie-policy.html',
  'es-EU': 'https://www.scotch-soda.com/es/es/cookie-policy.html',
  'it-EU': 'https://www.scotch-soda.com/it/it/cookie-policy.html',
  'fr-EU': 'https://www.scotch-soda.com/fr/fr/cookie-policy.html',
  'sv-EU': 'https://www.scotch-soda.com/se/sv/cookie-policy.html',

};

export default translations;
