/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { useState } from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { ErrorHandler } from 'services/errorHandling/useErrors';
import ErrorMessage from './ErrorMessage';

type Props = {
  textId: string;
  value?: boolean;
  onChange: (value: boolean) => void | undefined;
  errorHandler?: ErrorHandler;
};

export default function Checkbox(props: Props): JSX.Element {
  const errors = props.errorHandler && props.errorHandler.getErrors();
  const [checked, setChecked] = useState(false);

  const onChangeHandler = () => {
    const newValue = !checked;
    setChecked(newValue);
    props.onChange(newValue);
  };

  return (
    <div className="form-row">
      <div className="checkbox-container">
       {errors && <ErrorMessage errors={errors} />}
        <div className={classNames('body', errors ? 'body-error' : undefined)}>
          <span className="text">
            <FormattedMessage id={props.textId} />
          </span>
          <div
            className={classNames(
              'checkbox',
              checked ? 'checkbox-checked' : undefined,
              errors ? 'checkbox-error' : undefined
            )}
            onClick={onChangeHandler}
            role="button"
            tabIndex={0}
          />
        </div>
      </div>
    </div>
  );
}
