import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import evaConfig from '../../assets/config/eva-config.json';
import useQuery from '../../hooks/useQuery';

export default function AppleWallet(): JSX.Element {
  const [walletUrl, setWalletUrl] = useState<string>();
  const member = useParams<{id: string}>();
  const query = useQuery();

  useEffect(() => {
    const origin = query.get("origin");
      if (member.id) {
        setWalletUrl(
          `${evaConfig.metadata.walletUrl}?origin=${origin}&source=QR&customerID=${member.id}&barcodeValue=${member.id}`
        );
      }
      return () => setWalletUrl(undefined);
    }, [member, query])

  return (
    <div className='member-card-applewallet'>
      {walletUrl && (
        <a  href={walletUrl}>
          <button className="apple-wallet"><FormattedMessage id={"btn.addto.wallet"} /> </button>
        </a>
      )}
    </div>
  );
}
