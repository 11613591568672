export type MemberCardModel = {
  firstName: string;
  lastName: string;
  userId?: number;
  isPending?: boolean;
};

export const DefaultMemberCardModel: MemberCardModel = {
  firstName: '',
  lastName: '',
};
