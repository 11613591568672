import React, { useState, useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import Layout from 'components/shared/Layout';
import Input from 'components/shared/form/Input';
import Button from 'components/shared/form/Button';
import Checkbox from 'components/shared/form/Checkbox';
import { nameOf } from 'services/ObjectUtil';
import { useErrors } from 'services/errorHandling/useErrors';
import { AppContext } from 'services/appContext/AppContext';
import { NoErrors, AddError } from 'services/errorHandling/ErrorUtil';
import { ErrorsModel } from 'services/errorHandling/ErrorModel';
import useUserService from 'services/eva/UserService';
import { validateDob, validateModel } from './RegisterModelValidator';
import PageContent from 'components/shared/PageContent';
import Header from 'components/shared/header/Header';
import { privacyLinks, termsLinks } from 'translations';
import { DefaultRegisterModel, RegisterModel } from './RegisterModel';
import validateResponse from './CreateCustomerResponseValidator';
import PhoneNumberInput from 'components/shared/form/PhoneNumberInput';
import InquiriesDialog from './InquiriesDialog';
import { useHistory } from 'react-router-dom';

export default function Register(): JSX.Element {
  const { appContext, setAppContext } = useContext(AppContext);
  const { getErrorHandler, setErrors } = useErrors();
  const [model, setModel] = useState({
    ...DefaultRegisterModel,
    email: appContext.email ? appContext.email : ''
  });

  const history = useHistory();
  const items = [] as any;
  const userService = useUserService();
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [userCreated, setUserCreated] = useState(0);
  const [subscriptions, setSubscriptions] = useState({});
  const [subscriptionsWithInquiry, setSubscriptionsWithInquiry] = useState([]) as any;
  const [openModal, setOpenModal] = useState(false);
  const [inquiries, setInquiries] = useState([]) as any;

  useEffect(() =>  {
    async function updateSubscriptions() {
      if (userCreated) {
        const subscriptions = await userService.subscribeToAll(userCreated, model.emailOffers, model.smsOffers);
        if(subscriptions !== undefined) {
          const filterSubscriptionsWithInquiryArray  = subscriptions.Subscriptions.filter(subscription => subscription.InquiryID )
          setSubscriptionsWithInquiry(filterSubscriptionsWithInquiryArray);
          if (filterSubscriptionsWithInquiryArray.length !== 0) {
            filterSubscriptionsWithInquiryArray.map( async (subs: any) => {
              const inquiry = await userService.getInquiries(subs.InquiryID);
              if (inquiry !== undefined) {
                items.push(inquiry);
                setInquiries(items);
              }
            });   
          } else {
            history.push('/success');
          }
          setSubscriptions(subscriptions);
        }
        setOpenModal(true);
      }
    }

    if(Object.keys(subscriptions).length === 0 ) {
      updateSubscriptions();
    }

  }, [history, items, model.emailOffers, model.smsOffers, setInquiries, setSubscriptionsWithInquiry, subscriptions, userCreated, userService]);

  const handleResponse = async (response: EVA.Core.CreateCustomerResponse) => {
    const errors = validateResponse(response);

    if (NoErrors(errors)) {
      setAppContext({
        ...appContext,
        userToken: response.User.AuthenticationToken
      });
      setUserCreated(response.User.ID);
    } else {
      setErrors(errors);
      setSubmitDisabled(false);
    }
  };

  function handleValidateDob(dob: string) {
    const isValidDate = validateDob(dob);

    if (!isValidDate) {
      const errors: ErrorsModel = {};

      AddError(
        errors,
        nameOf<RegisterModel>('dateOfBirth'),
        'form.date-of-birth.valid',
        true
      );
      setErrors(errors);
      setSubmitDisabled(true);
    } else {
      setErrors({});
      setSubmitDisabled(false);
    }
  }

  function handleChange(e: any) {
    if (appContext.env !== 'eu') {
      handleValidateDob(e.target.value);
    }
    setModel({
      ...model,
      dateOfBirth: e.target.value ?? null
    });
  }

  const handleError = (response: any) => {
    const errors: ErrorsModel = {};

    if (response?.data.Error) {
      if (response?.data?.Error?.Type === 'PasswordPolicyViolation') {
        AddError(
          errors,
          nameOf<RegisterModel>('password'),
          response.data.Error.Message,
          false
        );
      }
      if (
        response.data.Error.Type === 'AGE' ||
        response.data.Error.Message.includes('DateOfBirth')
      ) {
        AddError(
          errors,
          nameOf<RegisterModel>('dateOfBirth'),
          response.data.Error.Message,
          false
        );
      } else {
        AddError(
          errors,
          nameOf<RegisterModel>('email'),
          response.data.Error.Message,
          false
        );
      }
    }

    setErrors(errors);
    setSubmitDisabled(false);
  };

  const submitHandler = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    const validationResult = validateModel(
      model,
      appContext.userRequirements,
      appContext.env
    );
    setErrors(validationResult);

    if (NoErrors(validationResult) && userCreated === 0) {
      setSubmitDisabled(true);

      userService
        .register(model)
        .then(x => handleResponse(x as EVA.Core.CreateCustomerResponse))
        .catch(x => handleError(x));
      setOpenModal(true);
    }
  };

  return (
    <Layout>
      <Header backButtonLink="/">
        <div className="register-title">
          <FormattedMessage id="register.title" />
        </div>
      </Header>
      <PageContent className="register">
        <form onSubmit={submitHandler}>
          <Input
            titleId="form.email.title"
            placeholderId="form.email.placeholder"
            type="email"
            value={model.email}
            errorHandler={getErrorHandler(nameOf<RegisterModel>('email'))}
            onChange={(e): void =>
              setModel({ ...model, email: e.target.value })
            }
          />
          <Input
            titleId="form.first-name.title"
            placeholderId="form.first-name.placeholder"
            type="text"
            value={model.firstName}
            errorHandler={getErrorHandler(nameOf<RegisterModel>('firstName'))}
            onChange={(e): void =>
              setModel({ ...model, firstName: e.target.value })
            }
            autoComplete="given-name"
          />
          <Input
            titleId="form.last-name.title"
            placeholderId="form.last-name.placeholder"
            type="text"
            value={model.lastName}
            errorHandler={getErrorHandler(nameOf<RegisterModel>('lastName'))}
            onChange={(e): void =>
              setModel({ ...model, lastName: e.target.value })
            }
            autoComplete="family-name"
          />
          <Input
            titleId="form.date-of-birth.title"
            type={appContext.env ? 'date' : 'date'}
            value={model.dateOfBirth}
            errorHandler={getErrorHandler(nameOf<RegisterModel>('dateOfBirth'))}
            placeholderId={'form.date-of-birth.placeholder'}
            onBlur={handleChange}
          />
          {appContext.env === 'us' && (
            <>
              <PhoneNumberInput
                titleId="form.phone-number.title"
                placeholderId="form.phone-number.placeholder"
                value={model.phoneNumber}
                errorHandler={getErrorHandler(
                  nameOf<RegisterModel>('phoneNumber')
                )}
                onChange={(e): void =>
                  setModel({
                    ...model,
                    phoneNumber: e.target.value
                  })
                }
              />
            </>
          )}
          <Checkbox
            textId="form.signup.newsletter"
            value={model.emailOffers}
            errorHandler={getErrorHandler(nameOf<RegisterModel>('emailOffers'))}
            onChange={(value): void =>
              setModel({
                ...model,
                emailOffers: value
              })
            }
          />
          {appContext.env === 'us' && (
            <>
              <Checkbox
                textId="form.sms-offers"
                value={model.smsOffers}
                errorHandler={getErrorHandler(
                  nameOf<RegisterModel>('smsOffers')
                )}
                onChange={(value): void =>
                  setModel({
                    ...model,
                    smsOffers: value
                  })
                }
              />
            </>
          )
          
          }
     
          <Button
            type="submit"
            textId="register.form.button"
            disabled={submitDisabled}
          />
          {inquiries.map((inquiry: any, index: number) => {
            return (
              <div key={index}>
                <InquiriesDialog
                  userID={userCreated}
                  inquiry={inquiry}
                  index={index + 1}
                  nrOfInquiries={subscriptionsWithInquiry.length}
                  showDialog={openModal}
                />
              </div>
            );
          })}

          <div className="terms-notice">
            <FormattedMessage
              id="form.terms-notice"
              values={{
                link: (
                  <a href={termsLinks[appContext.locale] ?? termsLinks.default}>
                    <FormattedMessage id="form.terms-link" />
                  </a>
                ),
                privacy: (
                  <a
                    href={
                      privacyLinks[appContext.locale] ?? privacyLinks.default
                    }>
                    <FormattedMessage id="form.terms-privacy" />
                  </a>
                )
              }}
            />
          </div>
        </form>
      </PageContent>
    </Layout>
  );
}
